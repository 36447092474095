import React from "react";
import { useParams } from "react-router-dom";
import { courseData } from "../constant/dummyData";
import Course from "./Course";
import PageBanner from "./PageBanner";

const CoursesByCategory = () => {
  const { slug } = useParams(); // Get category slug from URL
  const filteredCourses = courseData.filter(
    (course) => course.categorySlug === slug
  );

  return (
    <>
      <PageBanner title={slug.replace("-", " ")} pageName="Courses" />
      <div className="section-padding">
        <div className="container">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">
            {filteredCourses.map((course, index) => (
              <Course key={index} course={course} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursesByCategory;
