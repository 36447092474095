/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { clock, file } from "../constant/images";
import { Link } from "react-router-dom";

const course = ({ course, index }) => {
  return (
    <Link
      className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
      to={`/categories/course/${course.id}`}
      key={index}
    >
      <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
        <img
          src={course.image}
          alt=""
          className=" w-full h-full object-cover rounded-t-[8px]"
        />
      </div>
      <div className="course-content p-8">
        <h4 className=" text-xl mb-3 font-bold">{course.title}</h4>
        <div className="flex justify-between  space-x-3">
          <span className=" flex items-center space-x-2">
            <img src={file} alt="" />
            <span>{course.level}</span>
          </span>
          <span className=" flex items-center space-x-2">
            <img src={clock} alt="" />
            <span>{course.duration} Months</span>
          </span>
        </div>
      </div>
    </Link>
  );
};

export default course;
