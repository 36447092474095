import { BrowserRouter, Route, Routes } from "react-router-dom";

import HomeOne from "./Components/Home/HomeOne";
import ErrorPage from "./Components/Pages/404Page";
import AboutTwo from "./Components/Pages/AboutTwo";
import ContactUs from "./Components/Pages/ContactUs";
import Courses from "./Components/Pages/Courses";
// import CourseDetails from "./Components/CourseDetails";
// import CoursesByCategory from "./Components/CoursesByCategory";
// import CategoriesPage from "./Components/CategoriesPage";
import Categories from "./Components/Pages/Categories";
import CategorySingle from "./Components/Pages/CategorySingle";
// import SingleCourse from "./Components/Pages/SingleCourse";
import CourseDetails from "./Components/CourseDetails";
import Announcement from "./Components/Pages/Announcement";

function App() {
  return (
    <BrowserRouter>
      <div className="font-gilroy font-medium text-gray text-lg leading-[27px]">
        <Routes>
          <Route path="/" element={<HomeOne />} />
          <Route path="/about" element={<AboutTwo />} />
          {/* <Route path="courses" element={<Courses />} /> */}
          <Route path="/contacts" element={<ContactUs />} />
          {/* <Route path="/cd" element={<CourseDetails />} /> */}
          <Route path="*" element={<ErrorPage />} />
          <Route path="/categories/:slug" element={<CategorySingle />} />
          <Route path="/categories/course/:id" element={<CourseDetails />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/announcement" element={<Announcement />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
