import React from "react";
import { Link } from "react-router-dom";
import { courseData } from "../constant/dummyData";

const CategoriesPage = () => {
  // Extract unique categories from courseData
  const categories = [
    ...new Set(courseData.map((course) => course.categorySlug)),
  ];

  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">
      {categories.map((category, index) => (
        <Link
          key={index}
          to={`/categories/${category}`}
          className="bg-white shadow-lg hover:shadow-2xl hover:scale-105 transition-all duration-300 ease-in-out p-8 rounded-lg flex flex-col items-center justify-center text-center"
        >
          {/* Add Picture */}
          <img
            src={`/images/categories/${category}.gif`} // Comment moved outside JSX
            alt={category}
            className="w-24 h-24 object-cover rounded-full mb-4"
            width={100}
          />

          {/* Heading */}
          <div className="text-2xl font-bold capitalize mb-4 text-gray-800">
            {category.replace("-", " ")}
          </div>

          {/* Optional subheading */}
        </Link>
      ))}
    </div>
  );
};

export default CategoriesPage;
