import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

const Popup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 2 seconds when the component loads
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 2000);

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 5000,
          }}
        >
          <div
            style={{
              position: "relative",

              padding: "24px",
              textAlign: "center",
            }}
          >
            <button
              onClick={handleClosePopup}
              style={{
                position: "absolute",
                top: "35px",
                right: "40px",
                background: "none",
                border: "none",
                fontSize: "24px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "#666",
              }}
              aria-label="Close popup"
            >
              &times;
            </button>

            <p
              style={{
                marginBottom: "24px",
                fontSize: "16px",
                lineHeight: "1.5",
              }}
            >
              <img src="/images/popup/portal.jpg" width={500} />
            </p>
            <Link to="/announcement">
              <button
                style={{
                  backgroundColor: "#30bead",
                  color: "white",
                  fontWeight: "500",
                  padding: "12px 24px",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                More Details
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
