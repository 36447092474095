import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import CoursesByCategory from "../CoursesByCategory";

const CategorySingle = () => {
  return (
    <>
      <Header />

      <CoursesByCategory />

      <Footer />
    </>
  );
};

export default CategorySingle;
