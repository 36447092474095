import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";
import CategoriesPage from "../CategoriesPage";

const Categories = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Courses"} pageName="Courses" />
      <div className="nav-tab-wrapper tabs pt-10 section-padding-bottom">
        <div className="container">
          <CategoriesPage />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Categories;
