import React from "react";
import Header from "../Header";
import PageBanner from "../PageBanner";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Announcement = () => {
  return (
    <>
      <Header />
      <PageBanner
        title={"    PSEB Training Project"}
        pageTitle="PSEB Training Project"
      />

      <div className="bg-gray-100 min-h-screen p-8 container">
        <div className="max-w-7xl mx-auto bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-4xl font-bold text-center mb-6 text-blue-600">
            PSEB Training Project (2025)
          </h1>
          <p className="text-lg text-gray-700 text-center mb-8">
            RIPHAH International University in JV with IP-Sec & NorthTech
            Solutions has joined hands with Pakistan Software Export Board –
            Islamabad (PSEB), to deliver the following training and
            certifications duly sponsored by Pakistan Software Export Board for
            Information and Communication Technology Graduates, Information
            Technology Professional working in Public or Private Departments and
            Organizations.
          </p>

          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">
              Courses We Offer and Training Locations
            </h2>
            <p>
              We provide internationally recognized certifications and
              professional training in the following in-demand fields, delivered
              at top locations across Pakistan:
            </p>
            <div className="space-y-6">
              <div className="bg-blue-50 p-6 rounded-md">
                <h3 className="text-xl font-semibold">
                  AWS Certified Solution Architect
                </h3>
                <p>
                  Training Location:{" "}
                  <span className="font-medium">Karachi</span>
                </p>
                <p>
                  Gain expertise in designing scalable, reliable, and
                  cost-efficient cloud solutions.
                </p>
              </div>

              <div className="bg-blue-50 p-6 rounded-md">
                <h3 className="text-xl font-semibold">
                  Digital Marketing (Only Training)
                </h3>
                <p>
                  Training Location: <span className="font-medium">Quetta</span>
                </p>
                <p>
                  Master the latest strategies and tools to excel in the
                  ever-evolving world of digital marketing.
                </p>
              </div>

              <div className="bg-blue-50 p-6 rounded-md">
                <h3 className="text-xl font-semibold">
                  Game Development (Only Training)
                </h3>
                <p>
                  Training Location: <span className="font-medium">Quetta</span>
                </p>
                <p>
                  Unleash your creativity and build engaging games with modern
                  tools and programming skills.
                </p>
              </div>

              <div className="bg-blue-50 p-6 rounded-md">
                <h3 className="text-xl font-semibold">
                  MEAN/Full Stack Development (Only Training)
                </h3>
                <p>
                  Training Location: <span className="font-medium">Quetta</span>
                </p>
                <p>
                  Develop dynamic, full-stack web applications using the MEAN
                  stack framework.
                </p>
              </div>

              <div className="bg-blue-50 p-6 rounded-md">
                <h3 className="text-xl font-semibold">
                  Mobile App Development (Only Training)
                </h3>
                <p>
                  Training Location: <span className="font-medium">Quetta</span>
                </p>
                <p>
                  Learn to design and develop innovative mobile applications for
                  Android and iOS platforms.
                </p>
              </div>

              <div className="bg-blue-50 p-6 rounded-md">
                <h3 className="text-xl font-semibold">
                  PMP (Project Management Professional)
                </h3>
                <p>
                  Training Locations:{" "}
                  <span className="font-medium">
                    Islamabad, Lahore, Karachi, Peshawar
                  </span>
                </p>
                <p>
                  Achieve excellence in project management and gain insights
                  into managing complex projects effectively.
                </p>
              </div>
            </div>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Program Details</h2>
            <p>
              <span className="font-medium">Duration:</span> 40 hours
            </p>
            <p>
              <span className="font-medium">Schedules:</span>
            </p>
            <ul className="list-disc pl-8">
              <li>Weekdays: Monday to Friday, 9:00 AM to 5:00 PM</li>
              <li>Weekends: Saturday & Sunday, 9:00 AM to 5:00 PM</li>
            </ul>
            <p>
              <span className="font-medium">Breaks:</span> Enjoy lunch and two
              tea breaks with snacks daily.
            </p>
            <p>
              <span className="font-medium">Mode:</span> On-site and online
              options available, tailored to your convenience.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Selection Criteria</h2>
            <div className="space-y-6">
              <div className="bg-green-50 p-6 rounded-md">
                <h3 className="text-xl font-semibold">For IT Graduates</h3>
                <ul className="list-disc pl-8">
                  <li>Must hold Pakistani nationality with a valid CNIC.</li>
                  <li>Maximum age: 28 years.</li>
                  <li>
                    Relevance of the course with your academic qualifications.
                  </li>
                  <li>Must pass an IT test prepared by the training firm.</li>
                  <li>
                    Selection is on a first-come, first-served basis after the
                    announcement.
                  </li>
                </ul>
              </div>

              <div className="bg-green-50 p-6 rounded-md">
                <h3 className="text-xl font-semibold">
                  For IT Industry Professionals
                </h3>
                <ul className="list-disc pl-8">
                  <li>Pakistani nationality with a valid CNIC.</li>
                  <li>Maximum age: 50 years.</li>
                  <li>Employer recommendation or self-sponsorship.</li>
                  <li>
                    Participants are required to cover 10% of the training fee
                    through a demand draft.
                  </li>
                </ul>
              </div>

              <div className="bg-green-50 p-6 rounded-md">
                <h3 className="text-xl font-semibold">
                  For Public Sector Employees
                </h3>
                <ul className="list-disc pl-8">
                  <li>Must be nominated by your employer.</li>
                  <li>Pakistani nationality with a valid CNIC.</li>
                  <li>Maximum age: 50 years.</li>
                </ul>
              </div>

              <div className="bg-green-50 p-6 rounded-md">
                <h3 className="text-xl font-semibold">For PMP Participants</h3>
                <ul className="list-disc pl-8">
                  <li>Minimum 5 years of project management experience.</li>
                  <li>
                    Must have completed 35 hours of formal project management
                    education.
                  </li>
                  <li>
                    Experience in leading and directing 7,500 hours of projects.
                  </li>
                  <li>Pakistani nationality with a valid CNIC.</li>
                  <li>Maximum age: 55 years.</li>
                </ul>
              </div>
            </div>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Why Choose Us?</h2>
            <ul className="list-disc pl-8">
              <li>World-Class Training with hands-on experience.</li>
              <li>
                Professional Trainers certified in their respective fields.
              </li>
              <li>
                Access to modern training facilities and interactive e-learning
                platforms.
              </li>
              <li>
                Free Training for Public Sector Participants and subsidized
                opportunities for industry professionals.
              </li>
            </ul>
          </section>
          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">
              Prominent Features of these training’s are:
            </h2>
            <ul className="list-disc pl-8">
              <li>
                100% Free for graduates of Information Communication and
                Technology
              </li>
              <li>
                100% Free for Information Technology Professionals working in
                Public Sector IT Organizations or Department
              </li>
              <li>
                90% Subsidized for Professionals working in Private Sector’s IT
                Industry or Organization
              </li>
            </ul>
          </section>
          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">How to Register</h2>
            <ul className="list-decimal pl-8">
              <li>Fill out the registration form online.</li>
              <li>
                Attach all required documents (CNIC copy, employer
                recommendation, etc.).
              </li>
              <li>
                Submit your application along with the demand draft (if
                applicable).
              </li>
              <li>Wait for confirmation of your enrollment.</li>
            </ul>

            <p className="mt-4 font-medium">
              Take the first step toward a brighter future!
            </p>
          </section>

          <Link to={""}>
            <button
              className="btn btn-primary w-full text-center"
              onClick={() =>
                (window.location.href = "https://portal.ipsec.com.pk")
              }
            >
              Apply Now
            </button>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Announcement;
