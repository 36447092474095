import React, { useEffect } from "react";
import About from "../About";
import Banner from "../Banner";
import Brands from "../Brands";
import Courses from "../Courses";
import Feature from "../Feature";
import Footer from "../Footer";
import Header from "../Header";
import Platform from "../Platform";
import Popup from "../Popup";

const HomeOne = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <>
      <Popup />
      <Header />
      <Banner />
      <About />
      <Brands section_padding_bottom={"section-padding-bottom"} />
      <Feature />
      <Courses />
      <Platform />
      <Footer />
    </>
  );
};

export default HomeOne;
