import React from "react";
import { useParams } from "react-router-dom";
import {
  clockIcon,
  file2,
  starIcon,
  target,
  user,
  web,
  about5,
} from "../constant/images";
import { courseData } from "../constant/dummyData"; // Ensure this file contains your course data
import Header from "./Header";
import Footer from "./Footer";
import PageBanner from "./PageBanner";

const CourseDetails = () => {
  const { id } = useParams(); // Assuming you're passing the course ID as a parameter
  const course = courseData.find((course) => course.id === parseInt(id)); // Find the course by ID
  function ObjectivesComponent({ objectives }) {
    return <div dangerouslySetInnerHTML={{ __html: objectives }} />;
  }
  function DescriptionComponent({ description }) {
    return <div dangerouslySetInnerHTML={{ __html: description }} />;
  }
  function ScopeComponent({ Scope }) {
    return <div dangerouslySetInnerHTML={{ __html: Scope }} />;
  }
  if (!course) {
    return <div>Course not found</div>;
  }

  return (
    <>
      <Header />
      <PageBanner title={"Course Details"} pageName={course.title} />
      <div className="section-padding ">
        <div className="container">
          <div className="grid grid-cols-12 gap-[30px] text-justify">
            {/* Main Course Details */}
            <div className="lg:col-span-8 col-span-12">
              <div className="single-course-details ">
                <h2 className="text-left">{course.title}</h2>

                <div className="text-lg mt-6">
                  <h3 className="text-2xl font-semibold mb-4">
                    Course Description
                  </h3>
                  {/* <ObjectivesComponent description={course.description} /> */}
                  <DescriptionComponent description={course.description} />
                </div>
                {course.Scope && (
                  <div className="text-lg mt-6">
                    <h3 className="text-2xl font-semibold mb-4">
                      Course Scope
                    </h3>
                    <ScopeComponent Scope={course.Scope} />
                  </div>
                )}

                <div className="bg-[#F8F8F8] space-y-6 p-8 rounded-md my-8">
                  <h4 className="text-2xl">What You Will Learn</h4>
                  <ul className="grid sm:grid-cols-2 grid-cols-1 gap-6">
                    {course.whatYouWillLearn.map((point, index) => (
                      <li key={index} className="flex space-x-3">
                        <div className="flex-none relative top-1">
                          <img src={file2} alt="" />
                        </div>
                        <div className="flex-1 text-left">{point}</div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="text-lg mt-6">
                  {course.certification && (
                    <h3 className="text-2xl font-semibold mb-4">
                      Scope of International Certifications of{" "}
                      {course.certification}
                    </h3>
                  )}
                  {course.certification && (
                    <ObjectivesComponent objectives={course.objectives} />
                  )}
                </div>
              </div>
            </div>

            {/* Sidebar with Course Info */}
            <div className="lg:col-span-4 col-span-12">
              <div className="sidebarWrapper space-y-[30px]">
                <div className="widget custom-text space-y-5">
                  <ul className="list">
                    <li className="flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4">
                      <div className="flex-1 space-x-3 flex">
                        <img src={clockIcon} alt="" />
                        <div className="text-black font-semibold">Duration</div>
                      </div>
                      <div className="flex-none">{course.duration} Months</div>
                    </li>

                    <li className="flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4">
                      <div className="flex-1 space-x-3 flex">
                        <img src={target} alt="" />
                        <div className="text-black font-semibold">
                          Course Level
                        </div>
                      </div>
                      <div className="flex-none">{course.level}</div>
                    </li>

                    <li className="flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4">
                      <div className="flex-1 space-x-3 flex">
                        <img src={web} alt="" />
                        <div className="text-black font-semibold">Language</div>
                      </div>
                      <div className="flex-none">{course.language}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CourseDetails;
